/* eslint-disable no-unused-vars */
'use strict';
// eslint-disable-next-line no-unused-vars
/* global isAdmin, combinedUserID, isLoggedIn, navbarHidden, userType, formKey, returnUrl, WATCHMODE_CDN1 */
/* global welcomeBackMessage, watchmodeDomain */
import { GATEWAY_DOMAIN } from './constants';

document.getElementById('loginForm').addEventListener('submit', (evt) => {
    evt.preventDefault();
    doLogin(1);
});

function doLogin(type) {
    let message = '';
    const loginButton = document.getElementById('loginButton');
    const loginAlert = document.getElementById('loginFormAlert');
    loginButton.disabled = true;
    const data = new FormData();
    data.set('appPlatform', 'web');
    if (type == 1) {
        data.set('username', document.getElementById('loginEmail').value);
        data.set('password', document.getElementById('loginPassword').value);
        message = welcomeBackMessage;
    } else if (type == 2) {
        data.set('username', document.getElementById('email').value);
        data.set('password', document.getElementById('password').value);
        message = '';
    }
    data.set('formKey', formKey);
    fetch(GATEWAY_DOMAIN + '/login/', {
        method: 'POST',
        credentials: 'include',
        body: data
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.success) {
                if (returnUrl.includes('/signup') || returnUrl.includes('/login')) {
                    window.location.replace('https://' + watchmodeDomain + '/?message=' + message);
                } else {
                    window.location.replace(returnUrl + '?message=' + message);
                }
            } else {
                loginAlert.innerHTML = data.errorMessage;
                loginAlert.classList.add('alert', 'alert-danger', 'd-block');
                loginAlert.classList.remove('d-none');
                loginButton.disabled = false;
            }
        });
}

document.addEventListener('click', (event) => {
    if (event.target.matches('.clickAndDisable')) {
        event.target.onclick = (e) => {
            e.preventDefault();
        };
    }
});
